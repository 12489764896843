<template>
  <v-app>
    <v-app-bar
      app
      color="terciary"
      v-show="$route.path != '/Login' ? true : false"
      class="elevation-0"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      Bienvenido,&nbsp;<b>{{ username }}</b>
      <v-btn to="/profile" text class="btn-profile">
        <span class="mr-2">Mi perfil</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn text @click="logout" class="btn-logout">
        <span class="mr-2">Cerrar sesión</span>
        <v-icon>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      v-show="$route.path != '/Login' ? true : false"
      app
      color="secondary"
    >
      <div class="d-flex">
        <v-img
          max-height="50%"
          max-width="50%"
          contain
          src="./assets/logo.png"
          class="drawer-image"
        ></v-img>
      </div>
      <v-subheader class="menu-header">MAESTROS</v-subheader>
      <v-list>
        <v-list-item
          v-for="([styles, icon, text, route], i) in items"
          :key="i"
          link
          router
          :to="route"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="styles">{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-subheader class="menu-header" v-if="isAdmin"
        >CONFIGURACIÓN</v-subheader
      >
      <v-list v-if="isAdmin">
        <v-list-item
          v-for="([styles, icon, text, route], i) in itemsConfig"
          :key="i"
          link
          router
          :to="route"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :class="styles">{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main-app">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import authService from "@/services/authService.js";
import Cookies from "js-cookie";

export default {
  name: "App",

  metaInfo: {
    title: "Configura tu tarta",
  },

  components: {},

  data: () => ({
    items: [
      ["link", "mdi-basket", "Ventas", "/sales"],
      ["link", "mdi-shape", "Categorías", "/categories"],
      ["link", "mdi-format-list-bulleted", "Características", "/features"],
      ["link", "mdi-account-group", "Clientes", "/customers"],
      ["link", "mdi-cake", "Productos", "/products"],
    ],
    itemsConfig: [
      ["link", "mdi-account-multiple", "Usuarios", "/users"],
      //["link", "mdi-badge-account", "Roles", "/roles"],
      ["link", "mdi-domain", "Empresa", "/company"],
      ["link", "mdi-file-document-edit", "Impuestos", "/taxes"],
      ["link", "mdi-clipboard-text", "Informes", "/reports"],
    ],
    username: Cookies.get("userCredentials")
      ? JSON.parse(Cookies.get("userCredentials")).email
      : "Usuario",
    role: "Client",
    drawer: false,
    group: null,
  }),
  methods: {
    logout() {
      authService.logout();
      this.$router.replace("/Login");
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.role = Cookies.get("userLogged")
      ? JSON.parse(Cookies.get("userLogged")).role
      : "Client";
  },
  computed: {
    isAdmin() {
      if (this.role == "Admin") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
body {
  overflow: hidden;
}

.v-list-item .v-icon {
  color: var(--v-primary-base) !important;
}

.v-list-item--active {
  background-color: var(--v-accent-base);
  color: white !important;
}

.v-list-item--active .v-icon {
  color: white !important;
}

.btn-profile,
.btn-logout {
  margin-left: 10px;
}

.drawer-image {
  margin-top: 10px;
  margin-left: 10px;
}
.drawer-icon {
  margin-top: 10px;
  margin-right: 20px;
}

.menu-header {
  font-weight: bold;
}

.main-app.v-main {
  padding: 0 !important;
}
</style>
