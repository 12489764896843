import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#52a39c",
        secondary: "#f3f2ea",
        terciary: "#e6e5dd",
        primaryButton: "#448983",
        accent: "#b5945f",
        error: "#b71c1c",
      },
    },
    options: { customProperties: true },
  },
});
