import Product from "./product.js";
export default class SaleProduct {
  constructor(saleProductId, saleId, productId, basePrice, amount, subtotal) {
    this.saleProductId = saleProductId;
    this.saleId = saleId;
    this.productId = productId;
    this.product = new Product(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      [],
      false
    );
    this.basePrice = basePrice;
    this.amount = amount;
    this.saleProductOptions = [];
    this.subtotal = subtotal;
  }
}
