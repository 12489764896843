<template>
  <Products />
</template>

<script>
// @ is an alias to /src
import Products from "@/components/Products.vue";

export default {
  name: "products",
  components: {
    Products,
  },
};
</script>
