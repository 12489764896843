import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getReportByClient(reportQuery) {
    return axios.post(ENDPOINT_PATH + "sale/salebyclient", reportQuery, {
      headers: Utils.getHeaders(),
    });
  },
  getReportByProduct(reportQuery) {
    return axios.post(ENDPOINT_PATH + "sale/salebyproduct", reportQuery, {
      headers: Utils.getHeaders(),
    });
  },
};
