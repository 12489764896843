<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Maestros</p>
        <v-row
          ><v-icon>mdi-format-list-bulleted</v-icon>
          <p class="my-2">
            <v-icon>mdi-circle-small</v-icon> Características
          </p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center features-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de características</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-feature"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalFeature()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="featuresFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editModalFeature(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar característica</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="createModalFeatureOption(item)"
                    >mdi-flare</v-icon
                  >
                </template>
                <span>Crear opción de característica</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="viewFeatureOptions(item)"
                    >mdi-format-list-bulleted</v-icon
                  >
                </template>
                <span>Ver opción de características</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteFeature(item.featureId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar característica</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                v-if="item.active == 1"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.active == 0"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-minus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialogEdit"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualFeature.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Categoría</v-subheader>
                      </v-col>
                      <v-col cols="10">
                        <v-select
                          :items="categories"
                          item-text="name"
                          item-value="categoryId"
                          @change="getSubCategories"
                          return-object
                          filled
                          shaped
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Subcategoría</v-subheader>
                      </v-col>
                      <v-col cols="10">
                        <v-select
                          :items="loadSubCategories"
                          v-model="actualFeature.subCategoryId"
                          item-text="name"
                          item-value="subCategoryId"
                          filled
                          shaped
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Descripción</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualFeature.description"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="feature-actions d-flex justify-end"
                        >
                          <v-btn
                            class="feature-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="feature-btn"
                            color="primary"
                            @click="saveOrUpdateFeature"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogEditOpt"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitleOption }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualFeatureOption.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Precio base</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualFeatureOption.basePrice"
                          filled
                          shaped
                          type="number"
                          min="0"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="feature-actions d-flex justify-end"
                        >
                          <v-btn
                            class="feature-btn"
                            color="primary"
                            @click="dialogEditOpt = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="feature-btn"
                            color="primary"
                            @click="saveOrUpdateFeatureOption"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogOptTable"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title
                  >Listado de opciones de características</v-toolbar-title
                >
              </v-toolbar>
              <v-data-table
                :headers="headersOpt"
                :items="featureOptionsFiltered"
                class="table"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class=""
                        color="primary"
                        @click.stop="editModalFeatureOption(item)"
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Editar opción de característica</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        color="primary"
                        @click="deleteFeatureOption(item.featureOptionId)"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Eliminar opción de característica</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.active`]="{ item }">
                  <v-icon
                    v-if="item.active == 1"
                    small
                    v-bind="attrs"
                    class=""
                    color="primary"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    v-if="item.active == 0"
                    small
                    v-bind="attrs"
                    class=""
                    color="primary"
                    >mdi-minus-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-card-actions class="feature-actions d-flex justify-end">
                <v-btn
                  class="feature-btn"
                  color="primary"
                  @click="dialogOptTable = false"
                >
                  Cerrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Feature from "../models/feature.js";
import FeatureOption from "../models/featureOption.js";
import featureService from "../services/featureService.js";
import categoryService from "../services/categoryService.js";

export default {
  name: "features",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Sub Categoría", value: "subCategory.name", align: "left" },
        { text: "Descripción", value: "description", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      headersOpt: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Precio base", value: "basePrice", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      hideDeleted: true,
      hideDeletedSub: true,
      dialogCreate: false,
      newFeature: new Feature("", "", "", "", "", "", true),
      dialogEdit: false,
      actualFeature: new Feature("", "", "", "", "", "", true),
      features: [],
      dialogCreateOpt: false,
      newFeatureOption: new FeatureOption("", "", "", "", "", true),
      featureOptions: [],
      dialogOptTable: false,
      actualFeatureOption: new FeatureOption("", "", "", "", "", true),
      dialogEditOpt: false,
      categories: [],
      subcategories: [],
      allSubcategories: [],
      modalTitle: "Crear característica",
      modalTitleOption: "Crear opción de característica",
    };
  },
  methods: {
    saveOrUpdateFeature() {
      const feature = {
        FeatureId: this.actualFeature.featureId,
        CompanyId: this.actualFeature.companyId,
        Company: this.actualFeature.copmany,
        Name: this.actualFeature.name,
        Description: this.actualFeature.description,
        SubCategoryId: this.actualFeature.subCategoryId,
        Active: this.actualFeature.active,
      };
      if (feature.FeatureId == "" || feature.FeatureId == 0) {
        delete feature.FeatureId;
        delete feature.CompanyId;
        delete feature.Company;
        delete feature.Active;
        this.createFeature(feature);
      } else {
        this.updateFeature(feature);
      }
    },
    saveOrUpdateFeatureOption() {
      const featureOption = {
        FeatureOptionId: this.actualFeatureOption.featureOptionId,
        FeatureId: this.actualFeatureOption.featureId,
        Name: this.actualFeatureOption.name,
        BasePrice: this.actualFeatureOption.basePrice,
      };
      if (
        featureOption.FeatureOptionId == "" ||
        featureOption.FeatureOptionId == 0
      ) {
        delete featureOption.FeatureOptionId;
        this.createFeatureOption(featureOption);
      } else {
        this.updateFeatureOption(featureOption);
      }
    },
    createFeature(feature) {
      featureService
        .createFeature(feature)
        .then(() => {
          this.actualFeature = new Feature("", "", "", "", "", "", true);
          this.dialogEdit = false;
          featureService.getFeatures().then((response) => {
            this.features = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createFeatureOption(featureOption) {
      featureService
        .createFeatureOption(featureOption)
        .then(() => {
          this.actualFeatureOption = new FeatureOption(
            "",
            "",
            "",
            "",
            "",
            true
          );
          this.actualFeature = new Feature("", "", "", "", "", "", true);
          this.dialogEditOpt = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateFeature(feature) {
      featureService
        .updateFeature(feature)
        .then(() => {
          this.actualFeature = new Feature("", "", "", "", "", "", true);
          this.dialogEdit = false;
          featureService.getFeatures(this.actualFeature).then((response) => {
            this.features = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateFeatureOption(featureOption) {
      featureService
        .updateFeatureOption(featureOption)
        .then(() => {
          this.actualFeatureOption = new FeatureOption(
            "",
            "",
            "",
            "",
            "",
            true
          );
          this.dialogEditOpt = false;
          featureService
            .getFeatureOptions(this.actualFeature)
            .then((response) => {
              this.featureOptions = response.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFeature(featureId) {
      featureService
        .deleteFeature(featureId)
        .then(() => {
          featureService.getFeatures().then((response) => {
            this.features = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFeatureOption(featureOptionId) {
      featureService
        .deleteFeatureOption(featureOptionId)
        .then(() => {
          featureService
            .getFeatureOptions(this.actualFeature)
            .then((response) => {
              this.featureOptions = response.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createModalFeature() {
      this.modalTitle = "Crear característica";
      this.actualFeature = new Feature("", "", "", "", "", "", true);
      this.dialogEdit = true;
    },
    editModalFeature(item) {
      this.modalTitle = "Editar característica";
      this.actualFeature = new Feature(
        item.featureId,
        item.companyId,
        item.subCategoryId,
        item.subCategory,
        item.name,
        item.description,
        item.active
      );
      this.dialogEdit = true;
    },
    createModalFeatureOption(item) {
      this.modalTitleOption = "Crear opción de característica";
      this.actualFeature = new Feature(
        item.featureId,
        item.companyId,
        item.subCategoryId,
        item.subCategory,
        item.name,
        item.description,
        item.active
      );
      this.actualFeatureOption = new FeatureOption(
        "",
        item.featureId,
        item,
        "",
        "",
        true
      );
      this.dialogEditOpt = true;
    },
    viewFeatureOptions(item) {
      this.actualFeature = new Feature(
        item.featureId,
        item.companyId,
        item.subCategoryId,
        item.subCategory,
        item.name,
        item.description,
        item.active
      );
      featureService.getFeatureOptions(item).then((response) => {
        this.featureOptions = response.data;
      });
      this.dialogOptTable = true;
    },
    editModalFeatureOption(item) {
      this.modalTitleOption = "Editar opción de característica";
      this.actualFeatureOption = new FeatureOption(
        item.featureOptionId,
        item.featureId,
        item.feature,
        item.name,
        item.basePrice,
        item.active
      );
      this.dialogEditOpt = true;
    },
    getSubCategories(value) {
      categoryService
        .getSubCategories(value)
        .then((response) => {
          this.subcategories = response.data.filter(
            (subCategory) => subCategory.active == true
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubCategoryName(subCategoryId) {
      this.allSubcategories.forEach((subCategory) => {
        if (subCategory.subCategoryId == subCategoryId) {
          console.log("elemento if", subCategory.name);
          return subCategory.name;
        }
      });
    },
  },
  computed: {
    loadSubCategories() {
      return this.subcategories;
    },
    featuresFiltered() {
      if (this.hideDeleted) {
        return this.features.filter((feature) => feature.active == true);
      } else {
        return this.features;
      }
    },
    featureOptionsFiltered() {
      if (this.hideDeletedSub) {
        return this.featureOptions.filter(
          (featureOption) => featureOption.active == true
        );
      } else {
        return this.featureOptions;
      }
    },
  },
  mounted() {
    featureService.getFeatures().then((response) => {
      this.features = response.data;
    });
    categoryService.getCategories().then((response) => {
      this.categories = response.data.filter(
        (category) => category.active == true
      );
    });
    categoryService.getAllSubCategories().then((response) => {
      this.allSubcategories = response.data.filter(
        (subCategory) => subCategory.active == true
      );
    });
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.features-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
