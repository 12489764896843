export default class Feature {
  constructor(featureId, companyId, subCategoryId, subCategory, name, description, active) {
    this.featureId = featureId;
    this.companyId = companyId;
    this.subCategoryId = subCategoryId;
    this.subCategory = subCategory;
    this.name = name;
    this.description = description;
    this.active = active;
  }
}
