var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('div',{attrs:{"id":"background"}}),_c('div',{attrs:{"id":"background-shadow"}}),_c('v-layout',{staticClass:"main-container",attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"flexStyles",attrs:{"xs12":"","sm8":"","md8":""}},[_c('p',{staticClass:"page-title"},[_vm._v("Configuración")]),_c('v-row',[_c('v-icon',[_vm._v("mdi-cake")]),_c('p',{staticClass:"my-2"},[_c('v-icon',[_vm._v("mdi-circle-small")]),_vm._v(" Impuestos")],1)],1),_c('v-card',{staticClass:"elevation-2 rounded-lg d-flex flex-column align-center taxes-card",attrs:{"color":"secondary"}},[_c('v-toolbar',{staticClass:"elevation-0 toolbar",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Listado de impuestos")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search",attrs:{"append-icon":"mdi-magnify","label":"Buscar...","filled":"","rounded":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4 elevation-0 btn-add-tax",attrs:{"fab":"","dark":"","small":"","color":"primaryButton"},on:{"click":function($event){$event.stopPropagation();return _vm.createModalTax()}}},'v-btn',_vm.attrs,false),_vm.on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.taxesFiltered,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editTax(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar impuesto")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.deleteTax(item.taxId)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar impuesto")])])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active == 1)?_c('v-icon',_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',_vm.attrs,false),[_vm._v("mdi-check-circle")]):_vm._e(),(item.active == 0)?_c('v-icon',_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',_vm.attrs,false),[_vm._v("mdi-minus-circle")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',{staticClass:"elevation-2 rounded-lg d-flex flex-column align-center",attrs:{"color":"secondary"}},[_c('v-toolbar',{staticClass:"elevation-0 toolbar",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.modalTitle))])],1),_c('v-card-text',{staticClass:"card-text"},[_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"my-2",attrs:{"shrink":"true"}},[_vm._v("Nombre")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"filled":"","shaped":"","required":""},model:{value:(_vm.actualTax.name),callback:function ($$v) {_vm.$set(_vm.actualTax, "name", $$v)},expression:"actualTax.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"my-2"},[_vm._v("Porcentaje")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"filled":"","shaped":"","type":"number","min":"0","required":""},model:{value:(_vm.actualTax.percentage),callback:function ($$v) {_vm.$set(_vm.actualTax, "percentage", $$v)},expression:"actualTax.percentage"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card-actions',{staticClass:"tax-actions d-flex justify-end"},[_c('v-btn',{staticClass:"tax-btn",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogEdit = false}}},[_vm._v(" Cerrar")]),_c('v-btn',{staticClass:"tax-btn",attrs:{"color":"primary"},on:{"click":_vm.saveOrUpdate}},[_vm._v(" Guardar")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }