import axios from "axios";
import Cookies from "js-cookie";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  parseJwt(token) {
    var base64Payload = token.split(".")[1];
    var payload = Buffer.from(base64Payload, "base64");
    return JSON.parse(payload.toString());
  },
  setUserLogged(userLogged) {
    Cookies.set(
      "userLogged",
      JSON.stringify(this.parseJwt(userLogged.ticket)),
      {
        expires: 0.08,
      }
    );
    Cookies.set("userToken", userLogged.ticket, {
      expires: 0.08,
    });
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },
  login(email, password) {
    Cookies.set("userCredentials", JSON.stringify({ email, password }));
    return axios.post(
      ENDPOINT_PATH + "auth/Authenticate",
      {
        Username: email,
        Password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  logout() {
    Cookies.remove("userLogged");
  },
  getCurrentUser() {
    return axios.get(ENDPOINT_PATH + "auth/currentuser", {
      headers: Utils.getHeaders(),
    });
  },
  updateProfile(user) {
    return axios.put(ENDPOINT_PATH + "auth/updateprofile", user, {
      headers: Utils.getHeaders(),
    });
  },
  changePass(pass) {
    return axios.put(ENDPOINT_PATH + "auth/changepass", pass, {
      headers: Utils.getHeaders(),
    });
  },
};
