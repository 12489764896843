<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Configuración</p>
        <v-row
          ><v-icon>mdi-account-group</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Informes</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center reports-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Informes</v-toolbar-title>
          </v-toolbar>
          <v-tabs vertical>
            <v-tab>
              <v-icon left> mdi-account-group </v-icon>
              Ventas por clientes
            </v-tab>
            <v-tab>
              <v-icon left> mdi-cake </v-icon>
              Ventas por productos
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-row class="dates">
                  <v-col cols="4" class="inputs-fields">
                    <v-dialog
                      ref="dialogInitialCustomer"
                      v-model="modalInitialDateCustomers"
                      :return-value.sync="initialDateCustomers"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="initialDateCustomers"
                          label="Fecha inicial"
                          readonly
                          filled
                          shaped
                          prepend-icon="mdi-calendar-range"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="initialDateCustomers"
                        scrollable
                        locale="es"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalInitialDateCustomers = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialogInitialCustomer.save(
                              initialDateCustomers
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="4" class="inputs-fields">
                    <v-dialog
                      ref="dialogFinalCustomer"
                      v-model="modalFinalDateCustomers"
                      :return-value.sync="finalDateCustomers"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="finalDateCustomers"
                          label="Fecha final"
                          filled
                          shaped
                          prepend-icon="mdi-calendar-range"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="finalDateCustomers"
                        scrollable
                        locale="es"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalFinalDateCustomers = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialogFinalCustomer.save(finalDateCustomers)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="4" class="inputs-fields">
                    <v-autocomplete
                      v-model="customersSelected"
                      label="Clientes"
                      filled
                      shaped
                      prepend-icon="mdi-account-group"
                      :items="customers"
                      item-text="name"
                      item-value="clientId"
                      multiple
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-text class="chart">
                  <bar-chart
                    :chartdata="getChartDataCustomers"
                    :options="options"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-row class="dates">
                  <v-col cols="4" class="inputs-fields">
                    <v-dialog
                      ref="dialogInitialProduct"
                      v-model="modalInitialDateProducts"
                      :return-value.sync="initialDateProducts"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="initialDateProducts"
                          label="Fecha inicial"
                          readonly
                          filled
                          shaped
                          prepend-icon="mdi-calendar-range"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="initialDateProducts"
                        scrollable
                        locale="es"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalInitialDateProducts = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialogInitialProduct.save(initialDateProducts)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="4" class="inputs-fields">
                    <v-dialog
                      ref="dialogFinalProduct"
                      v-model="modalFinalDateProducts"
                      :return-value.sync="finalDateProducts"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="finalDateProducts"
                          label="Fecha final"
                          filled
                          shaped
                          prepend-icon="mdi-calendar-range"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="finalDateProducts"
                        scrollable
                        locale="es"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modalFinalDateProducts = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialogFinalProduct.save(finalDateProducts)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="4" class="inputs-fields">
                    <v-autocomplete
                      v-model="productsSelected"
                      label="Productos"
                      filled
                      shaped
                      prepend-icon="mdi-account-group"
                      :items="products"
                      item-text="name"
                      item-value="productId"
                      multiple
                      required
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-card-text class="chart">
                  <bar-chart
                    :chartdata="getChartDataProducts"
                    :options="options"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import reportService from "../services/reportService.js";
import BarChart from "./BarChart.vue";
import productService from "../services/productService.js";
import customerService from "../services/customerService.js";
import reportService from "../services/reportService.js";

export default {
  name: "reports",
  props: {
    source: String,
  },
  components: {
    BarChart,
  },
  data() {
    return {
      search: "",
      loaded: true,
      initialDateCustomers: "",
      finalDateCustomers: "",
      modalInitialDateCustomers: false,
      modalFinalDateCustomers: false,
      chartdataCustomer: {
        labels: [],
        datasets: [
          {
            label: "Ventas por clientes",
            backgroundColor: this.$vuetify.theme.themes.light.primary,
            data: [],
          },
        ],
      },
      initialDateProducts: "",
      finalDateProducts: "",
      modalInitialDateProducts: false,
      modalFinalDateProducts: false,
      chartdataProduct: {
        labels: [],
        datasets: [
          {
            label: "Ventas por productos",
            backgroundColor: this.$vuetify.theme.themes.light.primary,
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      dataSetsColors: [
        "#77dd77",
        "#836953",
        "#89cff0",
        "#99c5c4",
        "#9adedb",
        "#aa9499",
        "#aaf0d1",
        "#b2fba5",
        "#b39eb5",
        "#bdb0d0",
        "#bee7a5",
        "#befd73",
        "#c1c6fc",
        "#c6a4a4",
        "#cb99c9",
        "#fdfd96",
        "#ff6961",
        "#ff694f",
        "#ff9899",
        "#ffb7ce",
        "#ca9bf7",
      ],
      customers: [],
      products: [],
      customersSelected: [],
      productsSelected: [],
    };
  },
  watch: {
    initialDateCustomers: "getDateListCustomer",
    finalDateCustomers: "getDateListCustomer",
    customersSelected: "getDateListCustomer",
    initialDateProducts: "getDateListProducts",
    finalDateProducts: "getDateListProducts",
    productsSelected: "getDateListProducts",
  },
  methods: {
    getDateListCustomer() {
      if (
        this.initialDateCustomers != "" &&
        this.finalDateCustomers != "" &&
        this.customersSelected.length > 0
      ) {
        let currentDate = new Date(this.initialDateCustomers).toISOString();
        let endDate = new Date(this.finalDateCustomers)
          .toISOString()
          .replace("00:00:00.000Z", "23:59:59.999Z");
        let useCurrentDate = new Date(currentDate);
        let useEndDate = new Date(endDate);

        const requestObject = {
          initialDate: currentDate,
          finalDate: endDate,
          ids: this.customersSelected,
        };

        console.log("customersSelected", this.customersSelected);

        let dataSetsArray = [];
        let labels = [];

        reportService
          .getReportByClient(requestObject)
          .then((response) => {
            console.log("response ", response);
            const customerList = response.data.saleByClienttList;

            customerList.forEach((customer, index) => {
              let data = [];
              customer.groups.forEach((dataElement) => {
                data.push(dataElement.count);
              });

              let dataSet = {
                label: customer.name,
                backgroundColor: this.dataSetsColors[index],
                data: data,
              };

              dataSetsArray.push(dataSet);
            });

            let options = {
              year: "numeric",
              month: "long",
            };
            while (useCurrentDate <= useEndDate) {
              console.log(
                "fecha label",
                useCurrentDate.toLocaleDateString(undefined, options)
              );
              labels.push(useCurrentDate.toLocaleDateString("es-ES", options));
              useCurrentDate.setMonth(useCurrentDate.getMonth() + 1);
            }

            this.chartdataCustomer = {
              labels: labels,
              datasets: dataSetsArray,
            };
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getDateListProducts() {
      if (
        this.initialDateProducts != "" &&
        this.finalDateProducts != "" &&
        this.productsSelected.length > 0
      ) {
        let currentDate = new Date(this.initialDateProducts).toISOString();
        let endDate = new Date(this.finalDateProducts)
          .toISOString()
          .replace("00:00:00.000Z", "23:59:59.999Z");
        let useCurrentDate = new Date(currentDate);
        let useEndDate = new Date(endDate);

        let requestObject = {
          initialDate: currentDate,
          finalDate: endDate,
          ids: this.productsSelected,
        };

        let dataSetsArray = [];
        let labels = [];

        reportService.getReportByProduct(requestObject).then((response) => {
          const productList = response.data.productList;

          productList.forEach((product, index) => {
            let data = [];
            product.groups.forEach((dataElement) => {
              data.push(dataElement.count);
            });

            let dataSet = {
              label: product.name,
              backgroundColor: this.dataSetsColors[index],
              data: data,
            };

            dataSetsArray.push(dataSet);
          });

          let options = {
            year: "numeric",
            month: "long",
          };
          while (useCurrentDate <= useEndDate) {
            console.log(
              "fecha label",
              useCurrentDate.toLocaleDateString(undefined, options)
            );
            labels.push(useCurrentDate.toLocaleDateString("es-ES", options));
            useCurrentDate.setMonth(useCurrentDate.getMonth() + 1);
          }

          this.chartdataProduct = {
            labels: labels,
            datasets: dataSetsArray,
          };
        });
      }
    },
  },
  computed: {
    getChartDataCustomers() {
      return this.chartdataCustomer;
    },
    getChartDataProducts() {
      return this.chartdataProduct;
    },
  },
  mounted() {
    productService.getProducts().then((response) => {
      this.products = response.data;
    });

    customerService.getCustomers().then((response) => {
      this.customers = response.data;
    });
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.search-mobile {
  background-color: rgba(255, 255, 255, 0.534);
}

.reports-card {
  margin-left: 15%;
}

.theme--light.v-tabs > .v-tabs-bar {
  background-color: var(--v-secondary-base) !important;
}

.v-tab--active {
  color: #fff !important;
  background-color: var(--v-accent-base) !important;
}

.v-tabs-slider-wrapper {
  color: var(--v-accent-darken3) !important;
}

.v-menu__content .v-list .v-list-item--active {
  background-color: var(--v-primary-base-lighten4) !important;
}

.chart {
  background-color: var(--v-secondary-base) !important;
}

.dates {
  padding: 5%;
  background-color: var(--v-secondary-base) !important;
}

.inputs-fields {
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
