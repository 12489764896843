<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Configuración</p>
        <v-row
          ><v-icon>mdi-account-group</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Usuarios</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center users-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de usuarios</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-user"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalUser()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="usersFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editModalUser(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteUser(item.email)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar usuario</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                v-if="item.active == 1"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.active == 0"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-minus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-dialog v-model="dialogEdit" persistent max-width="800px">
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Nombre</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualUser.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Apellidos</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualUser.surname"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Email</v-subheader>
                      </v-col>
                      <v-col cols="4" v-if="modalTitle == 'Editar usuario'">
                        <v-text-field
                          v-model="actualUser.email"
                          filled
                          shaped
                          disabled
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" v-if="modalTitle == 'Crear usuario'">
                        <v-text-field
                          v-model="actualUser.email"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Contraseña</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualUser.newpassword"
                          filled
                          shaped
                          type="password"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >DNI/NIE</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualUser.identityNumber"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="createNew">
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Role</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-select
                          v-model="actualUser.role"
                          multiple
                          filled
                          shaped
                          :items="roles"
                          :item-text="name"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions class="user-actions d-flex justify-end">
                          <v-btn
                            class="user-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="user-btn"
                            color="primary"
                            @click="saveOrUpdate"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserProfile from "../models/userProfile.js";
import userService from "../services/userService.js";

export default {
  name: "users",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Apellidos", value: "surname", align: "left" },
        { text: "Email", value: "email", align: "left" },
        { text: "Roles", value: "roles", align: "left" },
        { text: "DNI/NIE", value: "identityNumber", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogEdit: false,
      hideDeleted: true,
      actualUser: new UserProfile("", "", "", "", "", "", true),
      createNew: false,
      users: [],
      modalTitle: "Crear usuario",
      roles: ["Admin", "Client"],
    };
  },
  methods: {
    saveOrUpdate() {
      const user = {
        Username: this.actualUser.email,
        Password: this.actualUser.newpassword,
        Name: this.actualUser.name,
        Surname: this.actualUser.surname,
        IdentityNumber: this.actualUser.identityNumber,
        Role: this.actualUser.roles,
        Active: this.actualUser.active,
      };
      console.log(user);
      if (this.modalTitle == "Crear usuario") {
        delete user.Active;
        this.createUser(user);
      } else {
        delete user.Password;
        delete user.Active;
        user.Role = this.actualUser.roles[0];
        this.updateUser(user);
      }
    },
    createUser(user) {
      userService
        .createUser(user)
        .then(() => {
          this.actualUser = new UserProfile("", "", "", "", "", "", true);
          this.dialogEdit = false;
          this.createNew = false;
          userService.getUsers().then((response) => {
            this.users = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateUser(user) {
      userService
        .updateUser(user)
        .then(() => {
          this.actualUser = new UserProfile("", "", "", "", "", "", true);
          this.dialogEdit = false;
          userService.getUsers().then((response) => {
            this.users = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteUser(email) {
      userService
        .deleteUser(email)
        .then(() => {
          userService.getUsers().then((response) => {
            this.users = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createModalUser() {
      this.modalTitle = "Crear usuario";
      this.actualUser = new UserProfile("", "", "", "", "", "", true);
      this.createNew = true;
      this.dialogEdit = true;
    },
    editModalUser(item) {
      this.modalTitle = "Editar usuario";
      this.actualUser = new UserProfile(
        item.email,
        item.name,
        item.surname,
        item.roles,
        item.identityNumber,
        "",
        item.active
      );
      this.createNew = false;
      this.dialogEdit = true;
    },
  },
  mounted() {
    userService.getUsers().then((response) => {
      this.users = response.data;
    });
  },
  computed: {
    usersFiltered() {
      if (this.hideDeleted) {
        return this.users.filter((user) => user.active == true);
      } else {
        return this.users;
      }
    },
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.users-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
