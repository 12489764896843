<template>
  <Sales />
</template>

<script>
// @ is an alias to /src
import Sales from "@/components/Sales.vue";

export default {
  name: "sales",
  components: {
    Sales,
  },
};
</script>
