export default class UserProfile {
  constructor(email, name, surname, roles, identityNumber, newpassword, active) {
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.roles = roles;
    this.identityNumber = identityNumber;
    this.newpassword = newpassword;
    this.active = active;
  }
}
