import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getProducts() {
    return axios.get(ENDPOINT_PATH + "product", {
      headers: Utils.getHeaders()
    });
  },
  createProduct(product) {
    return axios.post(ENDPOINT_PATH + "product", product, {
      headers: Utils.getHeaders()
    });
  },
  updateProduct(product) {
    return axios.put(ENDPOINT_PATH + "product", product, {
      headers: Utils.getHeaders()
    });
  },
  deleteProduct(productId) {
    return axios.delete(
      ENDPOINT_PATH + "product/" + productId + "?productId=" + productId, {
        headers: Utils.getHeaders()
      }
    );
  },
  uploadProductImagen(productImageVM) {
    return axios.post(ENDPOINT_PATH + "product/productimage", productImageVM, {
      headers: Utils.getHeaders()
    });
  },
  deleteProductImagen(productImageId) {
    return axios.delete(ENDPOINT_PATH + "product/productimage/" + productImageId + "?productImageId=" + productImageId, {
      headers: Utils.getHeaders()
    });
  },
};
