<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Ventas</p>
        <v-row
          ><v-icon>mdi-basket</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Ventas</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center sales-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de ventas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-sale"
              fab
              dark
              small
              color="primaryButton"
              v-bind="attrs"
              v-on="on"
              @click="createSale()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="salesFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editSale(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar venta</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteSale(item.saleId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar venta</span>
              </v-tooltip> -->
            </template>
          </v-data-table>
          <v-dialog v-model="dialogEdit" persistent max-width="600px">
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>Actualizar venta</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualSale.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Teléfono</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualSale.phoneNumber"
                          filled
                          shaped
                          type="tel"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Email</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualSale.email"
                          filled
                          shaped
                          type="email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Dirección</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualSale.address"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions class="sale-actions d-flex justify-end">
                          <v-btn
                            class="sale-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="sale-btn"
                            color="primary"
                            @click="updateSale"
                          >
                            Actualizar Venta</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Sale from "../models/sale.js";
import saleService from "../services/saleService.js";

export default {
  name: "sales",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Número de Factura", value: "invoiceNumber", align: "left" },
        { text: "Cliente", value: "client.name", align: "left" },
        { text: "Precio total", value: "totalPrice", align: "left" },
        { text: "Observaciones", value: "comments", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogCreate: false,
      hideDeleted: true,
      newSale: new Sale(0, "", "", "", "", "", ""),
      dialogEdit: false,
      actualSale: new Sale(0, "", "", "", "", "", ""),
      sales: [],
    };
  },
  methods: {
    createSale() {
      this.$router.push({ name: "saleform", params: { sale: null } });
    },
    editSale(sale) {
      this.$router.push({ name: "saleform", params: { sale: sale } });
    },
    deleteSale(saleId) {
      saleService
        .deleteSale(saleId)
        .then(() => {
          saleService.getSales().then((response) => {
            this.sales = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    saleService.getSales().then((response) => {
      this.sales = response.data;
    });
  },
  computed: {
    salesFiltered() {
      if (this.hideDeleted) {
        //no tienen atributo active de momento
        //return this.sales.filter((sale) => sale.active == true);
        return this.sales;
      } else {
        return this.sales;
      }
    },
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.sales-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
