<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Configuración</p>
        <v-row
          ><v-icon>mdi-cake</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Impuestos</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center taxes-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de impuestos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-tax"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalTax()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="taxesFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editTax(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar impuesto</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteTax(item.taxId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar impuesto</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                v-if="item.active == 1"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.active == 0"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-minus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-dialog v-model="dialogEdit" persistent max-width="800px">
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="actualTax.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2">Porcentaje</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="actualTax.percentage"
                          filled
                          shaped
                          type="number"
                          min="0"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions class="tax-actions d-flex justify-end">
                          <v-btn
                            class="tax-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="tax-btn"
                            color="primary"
                            @click="saveOrUpdate"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Tax from "../models/tax.js";
import taxService from "../services/taxService.js";

export default {
  name: "taxes",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Porcentaje", value: "percentage", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogEdit: false,
      hideDeleted: true,
      actualTax: new Tax(0, 1, "", "", "", true),
      taxes: [],
      modalTitle: "Crear impuesto",
    };
  },
  methods: {
    text: (item) => item.name + " — " + item.percentage,
    saveOrUpdate() {
      const tax = {
        TaxId: this.actualTax.taxId,
        TaxTypeId: this.actualTax.taxTypeId,
        TaxType: this.actualTax.taxType,
        Name: this.actualTax.name,
        Percentage: this.actualTax.percentage,
        Active: this.actualTax.active,
      };
      console.log(tax);
      if (tax.TaxId == "" || tax.TaxId == 0) {
        tax.TaxId = 0;
        this.createTax(tax);
      } else {
        this.updateTax(tax);
      }
    },
    createTax(tax) {
      taxService
        .createTax(tax)
        .then(() => {
          (this.actualTax = new Tax(0, 1, "", "", "", true)),
            (this.dialogEdit = false);
          taxService.getTaxes().then((response) => {
            this.taxes = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateTax(tax) {
      taxService
        .updateTax(tax)
        .then(() => {
          (this.actualTax = new Tax(0, 1, "", "", "", true)),
            (this.dialogEdit = false);
          taxService.getTaxes().then((response) => {
            this.taxes = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteTax(taxId) {
      taxService
        .deleteTax(taxId)
        .then(() => {
          taxService.getTaxes().then((response) => {
            this.taxes = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editTax(item) {
      this.modalTitle = "Editar impuesto";
      this.actualTax = new Tax(
        item.taxId,
        item.taxTypeId,
        item.taxType,
        item.name,
        item.percentage,
        item.active
      );
      console.log(this.actualTax);
      this.dialogEdit = true;
    },
    createModalTax() {
      this.modalTitle = "Crear impuesto";
      (this.actualTax = new Tax(0, 1, "", "", "", true)),
        (this.dialogEdit = true);
    },
  },
  mounted() {
    taxService.getTaxes().then((response) => {
      this.taxes = response.data;
    });
  },
  computed: {
    taxesFiltered() {
      if (this.hideDeleted) {
        return this.taxes.filter((tax) => tax.active == true);
      } else {
        return this.taxes;
      }
    },
  },
};
</script>

<style scoped>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.taxes-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}
.checkBoxTax {
  margin: 0px;
  margin-left: 20px;
}

.cancel-icon {
  position: absolute !important;
  top: -10px;
  right: -13px;
  color: #444 !important;
}

.disabled {
  opacity: 0.3 !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
