<template>
  <Categories />
</template>

<script>
// @ is an alias to /src
import Categories from "@/components/Categories.vue";

export default {
  name: "categories",
  components: {
    Categories,
  },
};
</script>
