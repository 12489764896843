import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getCategories() {
    return axios.get(ENDPOINT_PATH + "category", {
      headers: Utils.getHeaders(),
    });
  },
  getCategory(categoryId) {
    return axios.get(
      ENDPOINT_PATH + "category/" + categoryId + "?categoryId=" + categoryId,
      {
        headers: Utils.getHeaders(),
      }
    );
  },
  createCategory(category) {
    return axios.post(ENDPOINT_PATH + "category", category, {
      headers: Utils.getHeaders(),
    });
  },
  updateCategory(category) {
    return axios.put(ENDPOINT_PATH + "category", category, {
      headers: Utils.getHeaders(),
    });
  },
  deleteCategory(categoryId) {
    return axios.delete(
      ENDPOINT_PATH + "category/" + categoryId + "?categoryId=" + categoryId,
      {
        headers: Utils.getHeaders(),
      }
    );
  },
  getSubCategories(category) {
    return axios.get(
      ENDPOINT_PATH + "category/" + category.categoryId + "/subCategory",
      {
        headers: Utils.getHeaders(),
      }
    );
  },
  getSubCategory(subCategoryId) {
    return axios.get(
      ENDPOINT_PATH +
        "subCategory/" +
        subCategoryId +
        "?subCategoryId=" +
        subCategoryId,
      {
        headers: Utils.getHeaders(),
      }
    );
  },
  getAllSubCategories() {
    return axios.get(ENDPOINT_PATH + "subCategory", {
      headers: Utils.getHeaders(),
    });
  },
  createSubCategory(subCategory) {
    return axios.post(ENDPOINT_PATH + "subCategory", subCategory, {
      headers: Utils.getHeaders(),
    });
  },
  updateSubCategory(subCategory) {
    return axios.put(ENDPOINT_PATH + "subCategory", subCategory, {
      headers: Utils.getHeaders(),
    });
  },
  deleteSubCategory(SubCategoryId) {
    return axios.delete(
      ENDPOINT_PATH +
        "subCategory/" +
        SubCategoryId +
        "?SubCategoryId=" +
        SubCategoryId,
      {
        headers: Utils.getHeaders(),
      }
    );
  },
};
