<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Maestros</p>
        <v-row
          ><v-icon>mdi-account-group</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Clientes</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center customers-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de clientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search d-none d-sm-flex"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-customer"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalCustomer()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar..."
            filled
            rounded
            dense
            single-line
            hide-details
            class="search-mobile d-sm-none"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="customersFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editModalCustomer(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar cliente</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteCustomer(item.clientId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar cliente</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                v-if="item.active == 1"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.active == 0"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-minus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-dialog v-model="dialogEdit" persistent max-width="600px">
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualCustomer.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Teléfono</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualCustomer.phoneNumber"
                          filled
                          shaped
                          type="tel"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Email</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="actualCustomer.email"
                          filled
                          shaped
                          type="email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Dirección</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualCustomer.address"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="customer-actions d-flex justify-end"
                        >
                          <v-btn
                            class="customer-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="customer-btn"
                            color="primary"
                            @click="saveOrUpdate"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Customer from "../models/customer.js";
import customerService from "../services/customerService.js";

export default {
  name: "customers",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email", align: "left" },
        { text: "Teléfono", value: "phoneNumber", align: "left" },
        { text: "Dirección", value: "address", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogEdit: false,
      hideDeleted: true,
      actualCustomer: new Customer("", "", "", "", "", "", true),
      customers: [],
      modalTitle: "Crear cliente",
    };
  },
  methods: {
    saveOrUpdate() {
      const customer = {
        ClientId: this.actualCustomer.clientId,
        CompanyId: this.actualCustomer.companyId,
        Name: this.actualCustomer.name,
        Email: this.actualCustomer.email,
        PhoneNumber: this.actualCustomer.phoneNumber,
        Address: this.actualCustomer.address,
        Active: this.actualCustomer.active,
      };
      console.log(customer);
      if (customer.ClientId == "" || customer.ClientId == 0) {
        delete customer.ClientId;
        delete customer.CompanyId;
        delete customer.Active;
        this.createCustomer(customer);
      } else {
        this.updateCustomer(customer);
      }
    },
    createCustomer(customer) {
      customerService
        .createCustomer(customer)
        .then(() => {
          this.actualCustomer = new Customer("", "", "", "", "", "", true);
          this.dialogEdit = false;
          customerService.getCustomers().then((response) => {
            this.customers = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateCustomer(customer) {
      customerService
        .updateCustomer(customer)
        .then(() => {
          this.actualCustomer = new Customer("", "", "", "", "", "", true);
          this.dialogEdit = false;
          customerService.getCustomers().then((response) => {
            this.customers = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCustomer(customerId) {
      customerService
        .deleteCustomer(customerId)
        .then(() => {
          customerService.getCustomers().then((response) => {
            this.customers = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createModalCustomer() {
      this.modalTitle = "Crear cliente";
      (this.actualCustomer = new Customer("", "", "", "", "", "", true)),
        (this.dialogEdit = true);
    },
    editModalCustomer(item) {
      this.modalTitle = "Editar cliente";
      this.actualCustomer = new Customer(
        item.clientId,
        item.companyId,
        item.name,
        item.email,
        item.phoneNumber,
        item.address,
        item.active
      );
      this.dialogEdit = true;
    },
  },
  mounted() {
    customerService.getCustomers().then((response) => {
      this.customers = response.data;
    });
  },
  computed: {
    customersFiltered() {
      if (this.hideDeleted) {
        return this.customers.filter((customer) => customer.active == true);
      } else {
        return this.customers;
      }
    },
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.search-mobile {
  background-color: rgba(255, 255, 255, 0.534);
}

.customers-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
