<template>
  <Features />
</template>

<script>
// @ is an alias to /src
import Features from "@/components/Features.vue";

export default {
  name: "features",
  components: {
    Features,
  },
};
</script>
