import Cookies from "js-cookie";

export default {
  getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookies.get("userToken"),
    };
  },
};
