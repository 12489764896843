<template>
  <Saleform :sale="sale"></Saleform>
</template>

<script>
// @ is an alias to /src
import Saleform from "@/components/SaleForm.vue";

export default {
  name: "saleform",
  props: ["sale"],
  components: {
    Saleform,
  },
};
</script>
