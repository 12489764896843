import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getTaxes() {
    return axios.get(ENDPOINT_PATH + "tax", {
      headers: Utils.getHeaders(),
    });
  },
  createTax(tax) {
    return axios.post(ENDPOINT_PATH + "tax", tax, {
      headers: Utils.getHeaders(),
    });
  },
  updateTax(tax) {
    return axios.put(ENDPOINT_PATH + "tax", tax, {
      headers: Utils.getHeaders(),
    });
  },
  deleteTax(taxId) {
    return axios.delete(ENDPOINT_PATH + "tax/" + taxId + "?taxId=" + taxId, {
      headers: Utils.getHeaders(),
    });
  },
};
