export default class Customer {
  constructor(clientId, companyId, name, email, phoneNumber, address, active) {
    this.clientId = clientId;
    this.companyId = companyId;
    this.name = name;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.address = address;
    this.active = active;
  }
}
