export default class FeatureOption {
  constructor(featureOptionId, featureId, feature, name, basePrice, active) {
    this.featureOptionId = featureOptionId;
    this.featureId = featureId;
    this.feature = feature;
    this.name = name;
    this.basePrice = basePrice;
    this.active = active;
  }
}
