export default class SubCategory {
  constructor(subCategoryId, categoryId, category, name, description, active) {
    this.subCategoryId = subCategoryId;
    this.categoryId = categoryId;
    this.category = category;
    this.name = name;
    this.description = description;
    this.active = active;
  }
}
