export default class Sale {
  constructor(
    saleId,
    companyId,
    clientId,
    client,
    invoiceNumber,
    totalPrice,
    comments
  ) {
    this.saleId = saleId;
    this.companyId = companyId;
    this.clientId = clientId;
    this.client = client;
    this.invoiceNumber = invoiceNumber;
    this.totalPrice = totalPrice;
    this.comments = comments;
  }
}
