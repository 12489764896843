export default class Category {
  constructor(categoryId, companyId, company, name, description, active) {
    this.categoryId = categoryId;
    this.companyId = companyId;
    this.company = company;
    this.name = name;
    this.description = description;
    this.active = active;
  }
}
