import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getCompany() {
    return axios.get(ENDPOINT_PATH + "company", {
      headers: Utils.getHeaders(),
    });
  },
  updateCompany(company) {
    return axios.put(ENDPOINT_PATH + "company", company, {
      headers: Utils.getHeaders(),
    });
  },
  uploadCompanyImagen(imagen) {
    const form = new FormData();
    form.append("imagen", imagen);
    return axios.post(ENDPOINT_PATH + "company/setcompanylogo", form, {
      headers: Utils.getHeaders(),
    });
  },
};
