import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getCustomers() {
    return axios.get(ENDPOINT_PATH + "client", {
      headers: Utils.getHeaders(),
    });
  },
  createCustomer(customer) {
    return axios.post(ENDPOINT_PATH + "client", customer, {
      headers: Utils.getHeaders(),
    });
  },
  updateCustomer(customer) {
    return axios.put(ENDPOINT_PATH + "client", customer, {
      headers: Utils.getHeaders(),
    });
  },
  deleteCustomer(customerId) {
    return axios.delete(
      ENDPOINT_PATH + "client/" + customerId + "?clientId=" + customerId,
      {
        headers: Utils.getHeaders(),
      }
    );
  },
};
