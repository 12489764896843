<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Ventas</p>
        <v-row
          ><v-icon>mdi-basket</v-icon>
          <p class="my-2">
            <v-icon>mdi-circle-small</v-icon>Formulario de ventas
          </p></v-row
        >
        <v-card class="rounded-lg sales-card">
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Venta</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="card-text">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2" shrink="true"
                      >Cliente</v-subheader
                    >
                  </v-col>
                  <v-col cols="3">
                    <v-select v-model="sale.clientId" filled shaped :items="customers" item-text="name" item-value="clientId" required></v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="my-2" shrink="true"
                      >Número de pedido</v-subheader
                    >
                  </v-col>
                  <v-col cols="2">
                    <v-text-field v-model="sale.invoiceNumber" filled shaped required></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-subheader class="my-2" shrink="true">TOTAL</v-subheader>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="sale.totalPrice"
                      filled
                      shaped
                      disabled
                      required
                      suffix="€"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2">Observaciones</v-subheader>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      v-model="sale.comments"
                      filled
                      shaped
                      counter="1000"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-card-title>Productos</v-card-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mx-4 elevation-0 btn-add-sale"
                    fab
                    dark
                    small
                    color="primaryButton"
                    v-bind="attrs"
                    v-on="on"
                    @click="addSaleProduct()"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-row>
                <v-row>
                  <v-list class="product-list">
                    <template v-for="(item, index) in saleProducts">
                      <v-list-item
                        class="list-item rounded-lg flex-column"
                        :key="item.saleProductId"
                      >
                        <v-row class="product-data align-center">
                          <v-col cols="6">
                            <v-row>
                              <v-col cols="4">
                                <v-subheader class="my-2"
                                  >Producto {{ index + 1 }} </v-subheader
                                >
                              </v-col>
                              <v-col cols="8">
                                <v-autocomplete
                                  v-model="item.productId"
                                  :items="products"
                                  item-text="name"
                                  item-value="productId"
                                  @change="loadFeatures($event, index)"
                                  return-object
                                  filled
                                  shaped
                                  required
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4">
                                <v-subheader class="my-2"
                                  >Precio base</v-subheader
                                >
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="item.basePrice"
                                  filled
                                  shaped
                                  @change="calculateTotalPrice()"
                                  disabled
                                  required
                                  suffix="€"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-subheader class="my-2">Cantidad</v-subheader>
                              </v-col>
                              <v-col cols="2">
                                <v-text-field
                                  v-model.number="item.amount"
                                  filled
                                  shaped
                                  type="number"
                                  min="1"
                                  @change="calculateTotalPrice()"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col class="img-container" cols="5">
                            <v-img class="img-product" v-for="(img, indexImage) in saleProductsData[index].product.productImages" 
                            :key="indexImage" 
                            :alt="img.description"
                            :title="img.description"
                            contain
                            :src="imgUrl(img)"
                            >
                          </v-col>
                        </v-row>
                        <v-row class="product-features">
                          <v-list class="feature-list">
                            <template
                              v-for="(
                                feature, indexFeature
                              ) in saleProductsData[index].features"
                            >
                              <v-divider :key="indexFeature"></v-divider>
                              <v-list-item
                                class="list-features-item justify-center"
                                :key="feature.featureId"
                              >
                                <v-row>
                                  <v-col cols="2">
                                    <v-subheader class="my-2" shrink="true">{{
                                      feature.name
                                    }}</v-subheader>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-select
                                      v-model="saleProducts[index].saleProductOptions[indexFeature].featureOptionId"
                                      filled
                                      shaped
                                      :items="saleProductsData[index].featureOptions[indexFeature]"
                                      @change="loadFeatureOption(
                                        $event,
                                        index,
                                        indexFeature
                                      )"
                                      item-text="name"
                                      item-value="featureOptionId"
                                      return-object
                                      required
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-subheader class="my-2" shrink="true"
                                      >Precio</v-subheader
                                    >
                                  </v-col>
                                  <v-col cols="2">
                                    <v-text-field
                                      v-model="saleProducts[index].saleProductOptions[indexFeature].basePrice"
                                      filled
                                      shaped
                                      suffix="€"
                                      disabled
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="1">
                                    <v-subheader class="my-2" shrink="true"
                                      >Cantidad</v-subheader
                                    >
                                  </v-col>
                                  <v-col cols="2">
                                    <v-text-field
                                      v-model.number="saleProducts[index].saleProductOptions[indexFeature].amount"
                                      filled
                                      type="number"
                                      min="0"
                                      @change="calculateTotalPrice()"
                                      shaped
                                      required
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-list-item>
                            </template>
                          </v-list>
                        </v-row>
                        <v-row class="sale-product-actions justify-end">
                          <v-btn
                            class="sale-btn"
                            color="primary"
                            @click="removeSaleProduct(index)"
                          >
                            <v-icon color="secondary">mdi-delete</v-icon>
                          </v-btn>
                        </v-row>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-actions class="sale-actions d-flex justify-end">
                      <v-btn
                        class="sale-btn"
                        color="primary"
                        @click="backToSales()"
                      >
                        Cerrar</v-btn
                      >
                      <v-btn
                        class="sale-btn"
                        color="primary"
                        @click="saveOrUpdate"
                      >
                        Guardar</v-btn
                      >
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Sale from "../models/sale.js";
import SaleProduct from "../models/saleProduct.js";
import SaleProductOption from "../models/saleProductOption.js";
import Product from "../models/product.js";
import productService from "../services/productService.js";
import customerService from "../services/customerService.js";
import featureService from "../services/featureService.js";
import saleService from "../services/saleService.js";

export default {
  name: 'saleform',
  props: ['sale'],
  data() {
    return {
      products: [],
      customers: [],
      saleProducts: [],
      saleProductsData: [],
    };
  },
  methods: {
    imgUrl: (img) =>
      process.env.VUE_APP_RESOURCES + img.filePath.replaceAll("\\", "/"),
    loadFeatures(value, index) {
      featureService
        .getFeaturesBySubcategory(value.subCategoryId)
        .then((response) => {
          const features = response.data;
          this.$set(this.saleProducts, index, new SaleProduct(
            0,
            this.sale.saleId,
            value.productId,
            value.basePrice,
            1,
            [],
            value.basePrice
          ));
          const saleProductData = {
            product: value,
            features: features,
            featureOptions: [],
          };
          this.$set(this.saleProductsData, index, saleProductData);

          features.forEach((feature) => {
            featureService.getFeatureOptions(feature).then((response) => {
              const featureOptions = response.data;
              this.saleProductsData[index].featureOptions.push(featureOptions);
              this.saleProducts[index].saleProductOptions.push(new SaleProductOption(
                  0,
                  this.saleProducts[index].saleProductId,
                  0,
                  0,
                  0
              ));
            });
          });

          this.calculateTotalPrice();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadFeatureOption(value, index, indexFeature) {
      
      this.$set(this.saleProducts[index].saleProductOptions, indexFeature, new SaleProductOption(
            0,
            this.saleProducts[index].saleProductId,
            value.featureOptionId,
            value.basePrice,
            1
          ));
      this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      let totalPrice = 0;
      this.saleProducts.forEach((saleProduct) => {
        let optionsPrice = 0;
        let saleProductPrice = 0;
        saleProduct.saleProductOptions.forEach(
          (saleProductOption) => {
            optionsPrice += saleProductOption.basePrice * saleProductOption.amount;
          }
        );
        saleProductPrice += saleProduct.basePrice * saleProduct.amount;
        totalPrice += saleProductPrice + optionsPrice;
        saleProduct.subTotal = saleProductPrice + optionsPrice;
      });
      this.sale.totalPrice = totalPrice;
      return totalPrice;
    },
    addSaleProduct() {
      const saleProduct = new SaleProduct(0, 0, 0, 0, 0, 0);
      this.saleProducts.push(saleProduct);
      this.saleProductsData.push({ features: [], featureOptions:[], product: new Product("", "", "", "", "", "", "", "", "", "", "", "", [], false) });
    },
    removeSaleProduct(index) {
      if (this.saleProducts.length > 1) {
        this.saleProducts.splice(index, 1);
        this.saleProductsData.splice(index, 1);
        this.calculateTotalPrice();
      }
    },
    saveOrUpdate() {
      let finalSaleProducts = [];
      this.saleProducts.forEach((saleProduct) => {
        const finalSaleProduct = {
          saleProductId: saleProduct.saleProductId,
          saleId: this.sale.saleId,
          productId: saleProduct.productId,
          basePrice: saleProduct.basePrice,
          amount: saleProduct.amount,
          subTotal: saleProduct.subTotal,
          saleProductOptions: saleProduct.saleProductOptions,
        };
        finalSaleProducts.push(finalSaleProduct);
      });
      const sale = {
        SaleId: this.sale.saleId,
        ClientId: this.sale.clientId,
        InvoiceNumber: this.sale.invoiceNumber,
        TotalPrice: this.sale.totalPrice,
        Comments: this.sale.comments,
        SaleProducts: finalSaleProducts,
      };
      if (sale.SaleId == "" || sale.SaleId == 0) {
        this.createSale(sale);
      } else {
        this.updateSale(sale);
      }
    },
    createSale(sale) {
      console.log("crear objeto de venta: ",sale);
      saleService
        .createSale(sale)
        .then(() => {
          this.$router.push("/sales");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSale(sale) {
      console.log("actualizar objeto de venta: ",sale);
      saleService
        .updateSale(sale)
        .then(() => {
          this.$router.push("/sales");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    backToSales() {
      this.$router.push("/sales");
    },
  },
  created() {
    if (this.sale != null) {
      saleService.getSaleById(this.sale.saleId).then((response) => {
        if (response.data.saleProducts.length < 1) {
          this.addSaleProduct();
        } else {
          response.data.saleProducts.forEach( (saleProduct) => {
            const features = saleProduct.product.subCategory.features
            let featureOptionsArray = [];
            features.forEach((feature) => {
              featureOptionsArray.push(feature.featureOptions);
            });
            const saleProductData = {
              product: saleProduct.product,
              features: features,
              featureOptions: featureOptionsArray,
            };
            if (saleProduct.saleProductOptions.length < saleProductData.featureOptions.length) {
              for (let i = saleProduct.saleProductOptions.length; i < saleProductData.featureOptions.length; i++) {
                saleProduct.saleProductOptions.push(new SaleProductOption(
                  0,
                  saleProduct.saleProductId,
                  0,
                  0,
                  0
                ));
              }
            }
            this.saleProducts.push(saleProduct);
            this.saleProductsData.push(saleProductData); 
          });
        }
      });
    }
    else {
      this.sale = new Sale(0, "", "", "", "", "", "");
      this.saleProducts.push(new SaleProduct(0, 0, 0, 0, 0, 0));
      this.saleProductsData.push({ features: [], featureOptions:[], product: new Product("", "", "", "", "", "", "", "", "", "", "", "", [], false) });
    }

    productService.getProducts().then((response) => {
      this.products = response.data;
    });

    customerService.getCustomers().then((response) => {
      this.customers = response.data;
    });

  },

};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
  background: var(--v-secondary-base);
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.product-list {
  width: 100%;
  background: var(--v-secondary-base) !important;
}

.product-data,
.product-features {
  width: 100%;
}

.feature-list {
  background: var(--v-terciary-base) !important;
}

.list-features-item {
  padding: 2% 0%;
  padding-bottom: 0;
  background: var(--v-terciary-base) !important;
}

.list-item {
  padding: 5% 2%;
  margin-bottom: 10px;
  min-height: 0;
  background: var(--v-terciary-base) !important;
}

.img-container {
  display: flex;
  flex-flow: row;
  justify-content: start;
  align-content: flex-start;
  align-items: flex-start;
  margin: 10px;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: hidden;
  overflow-x: auto;
  margin-left: 5%;
}
.img-product {
  height: 100px;
  width: 100px;
  margin: 10px;
  overflow: initial;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.sales-card {
  margin-left: 15%;
}

.sale-product-actions {
  width: 100%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
