<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Configuración</p>
        <v-row
          ><v-icon>mdi-domain</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Empresa</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center company-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Datos de empresa</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="card-text">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field 
                        v-model="company.name" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">CIF</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field 
                        v-model="company.companyIdentifier" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Alias</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field 
                        v-model="company.alias" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Dirección</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field 
                        v-model="company.address" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2">Teléfono</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field 
                        v-model="company.phoneNumber" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-subheader class="my-2">Email</v-subheader>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field 
                        v-model="company.email" 
                        filled 
                        shaped>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="column-flex" cols="3">
                    <v-img
                      max-height="100%"
                      max-width="100%"
                      contain
                      v-bind:src="companyUrlImagen"
                    ></v-img>
                    <v-btn class="upload-btn" @click="chooseFile()">
                      <v-icon class="upload-icon">mdi-cloud-upload</v-icon> 
                        Upload img
                    </v-btn>
                    <input id="fileUpload" @change="uploadImage" type="file" ref="file" accept="image/bmp, 
                    image/jpeg, image/png" hidden/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-actions class="company-actions d-flex justify-start">
                      <v-btn class="company-btn" 
                      color="primary" 
                      @click="updateCompany"
                        >Guardar Cambios</v-btn
                      >
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Company from "../models/company.js";
import companyService from "../services/companyService.js";
export default {
  name: "company",
  props: {
    source: String,
  },
  data() {
    return {
      company: new Company("", "", "", "", "", "", "", "", "", "", true),
      companyUrlImagen: "https://tutarta.com/wp-content/uploads/2019/10/300x90-1.png",
    };
  },
  methods: {
    updateCompany() {
      const company = {
        CompanyId: this.company.companyId,
        name: this.company.name,
        companyIdentifier: this.company.companyIdentifier,
        alias: this.company.alias,
        address: this.company.address,
        phoneNumber: this.company.phoneNumber,
        email: this.company.email,
      };
      companyService
        .updateCompany(company)
        .catch((error) => {
          console.log(error);
        });
    },
    uploadImage(e) {
      companyService
      .uploadCompanyImagen(e.target.files[0])
      .then(data => {
        if(data.data != null && data.data != "") {
          this.company.logoPath = data.data;
          this.companyUrlImagen = process.env.VUE_APP_RESOURCES + this.company.logoPath.replaceAll('\\', '/');
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    chooseFile(){
      document.getElementById("fileUpload").click();
    }
  },
  mounted() {
    companyService.getCompany().then((response) => {
      this.company = response.data;
      if(this.company.logoPath != null && this.company.logoPath != "") {
        this.companyUrlImagen = process.env.VUE_APP_RESOURCES + this.company.logoPath.replaceAll('\\', '/');
      }
    });
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 15px;
}

.company-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

.company-actions {
  margin-top: 30px;
  width: 100%;
}

.company-btn {
  width: 200px;
}

.upload-btn {
  width: 150px;
  margin: 10px;
  color:#444 !important;
  background: whitesmoke !important;
  background-color: whitesmoke !important;
}

.upload-icon {
  margin-right: 10px;
  margin-left: 5px;
}

.column-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;  
}

#background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 275px;
    background-color: var(--v-primary-base);
    z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
    color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title{
  font-size: 1.4rem !important;
}
</style>
