<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">
          <v-icon>mdi-account</v-icon><v-icon>mdi-circle-small</v-icon>Perfil
        </p>
        <v-row> <p class="my-2"></p></v-row>
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center profile-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Datos de perfil</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="card-text">
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2" shrink="true"
                      >Usuario</v-subheader
                    >
                  </v-col>
                  <v-col cols="10">
                    <v-text-field v-model="user.email" disabled filled shaped>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2">Nombre</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="user.name" filled shaped>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="my-2">Apellidos</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="user.surname" filled shaped>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2" shrink="true"
                      >DNI/NIE</v-subheader
                    >
                  </v-col>
                  <v-col cols="10">
                    <v-text-field v-model="user.identityNumber" filled shaped>
                    </v-text-field>
                  </v-col>
                </v-row>
                <br />
                <v-divider></v-divider>
                <br />
                <p class="title-pass">Modificar Contraseña</p>
                <br />
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="my-2">Nueva contraseña</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      type="password"
                      v-model="user.newpassword"
                      filled
                      shaped
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="my-2">Repetir contraseña</v-subheader>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      type="password"
                      v-model="repeatnewpassword"
                      filled
                      shaped
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-actions class="profile-actions d-flex justify-end">
                      <v-btn
                        class="profile-btn"
                        color="primary"
                        @click="updateUser"
                        >Guardar Cambios</v-btn
                      >
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserProfile from "../models/userProfile";
import authService from "../services/authService.js";
export default {
  name: "profile",
  props: {
    source: String,
  },
  data() {
    return {
      user: new UserProfile("", "", "", "", "", "", true),
      repeatnewpassword: "",
    };
  },
  methods: {
    updateUser() {
      const user = {
        email: this.user.email,
        name: this.user.name,
        surname: this.user.surname,
        identityNumber: this.user.identityNumber,
        newpassword: null,
      };

      console.log(this.user);
      console.log("user.newpassword");
      console.log(this.user.newpassword);
      console.log("this.repeatnewpassword");
      console.log(this.repeatnewpassword);

      if (
        this.user.newpassword.trim() != "" &&
        this.repeatnewpassword.trim() != ""
      ) {
        if (this.user.newpassword.trim() == this.repeatnewpassword.trim()) {
          user.newpassword = this.user.newpassword.trim();
        } else {
          alert("Las contraseñas nuevas tienen que ser iguales.");
        }
      }
      authService
        .updateProfile(user)
        .then(() => {
          this.user.newpassword = "";
          this.repeatnewpassword = "";
        })
        .catch((error) => {
          console.log(error);
          this.user.newpassword = "";
          this.repeatnewpassword = "";
        });
    },
  },
  mounted() {
    authService.getCurrentUser().then((response) => {
      this.user = new UserProfile(
        response.data.email,
        response.data.name,
        response.data.surname,
        "",
        response.data.identityNumber,
        "",
        true
      );
    });
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 15px;
}

.profile-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

.profile-actions {
  width: 100%;
}

.profile-btn {
  width: 200px;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}

.title-pass {
  font-weight: bold;
}
</style>
