export default class Tax {
  constructor(taxId, taxTypeId, taxType, name, percentage, active) {
    this.taxId = taxId;
    this.taxTypeId = taxTypeId;
    this.taxType = taxType;
    this.name = name;
    this.percentage = percentage;
    this.active = active;
  }
}
