import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getSales() {
    return axios.get(ENDPOINT_PATH + "sale", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  getSaleById(saleId) {
    return axios.get(ENDPOINT_PATH + "sale/" + saleId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  createSale(sale) {
    return axios.post(ENDPOINT_PATH + "sale", sale, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  updateSale(sale) {
    return axios.put(ENDPOINT_PATH + "sale", sale, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  deleteSale(saleId) {
    return axios.delete(
      ENDPOINT_PATH + "sale/" + saleId + "?saleId=" + saleId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("userToken"),
        },
      }
    );
  },
};
