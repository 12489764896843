import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Customers from "../views/Customers.vue";
import Products from "../views/Products.vue";
import Categories from "../views/Categories.vue";
import Company from "../views/Company.vue";
import Users from "../views/Users.vue";
// import Roles from "../views/Roles.vue";
import Features from "../views/Features.vue";
import Taxes from "../views/Taxes.vue";
import Reports from "../views/Reports.vue";
import Sales from "../views/Sales.vue";
import SaleForm from "../views/SaleForm.vue";
import authService from "../services/authService";
import Sale from "../models/sale.js";

Vue.use(VueRouter);

let checkLogin = (to, from, next) => {
  if (authService.getUserLogged()) {
    next();
  } else {
    router.replace("/Login");
  }
};

const routes = [
  {
    path: "/",
    redirect: "sales",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
    beforeEnter: checkLogin,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    beforeEnter: checkLogin,
  },
  // {
  //   path: "/roles",
  //   name: "roles",
  //   component: Roles,
  //   beforeEnter: checkLogin,
  // },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: checkLogin,
  },
  {
    path: "/customers",
    name: "customers",
    component: Customers,
    beforeEnter: checkLogin,
  },
  {
    path: "/products",
    name: "products",
    component: Products,
    beforeEnter: checkLogin,
  },
  {
    path: "/categories",
    name: "categories",
    component: Categories,
    beforeEnter: checkLogin,
  },
  {
    path: "/features",
    name: "features",
    component: Features,
    beforeEnter: checkLogin,
  },
  {
    path: "/taxes",
    name: "taxes",
    component: Taxes,
    beforeEnter: checkLogin,
  },
  {
    path: "/sales",
    name: "sales",
    component: Sales,
    beforeEnter: checkLogin,
  },
  {
    path: "/saleform",
    name: "saleform",
    component: SaleForm,
    beforeEnter: checkLogin,
    props: (route) => ({
      sale: new Sale(0, "", "", "", "", "", ""),
      ...route.params,
    }),
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    beforeEnter: checkLogin,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
