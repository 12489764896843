<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Maestros</p>
        <v-row
          ><v-icon>mdi-cake</v-icon>
          <p class="my-2"><v-icon>mdi-circle-small</v-icon> Productos</p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center products-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de productos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-product"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalProduct()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="productsFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editProduct(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar producto</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteProduct(item.productId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar producto</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog v-model="dialogEdit" persistent max-width="800px">
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="actualProduct.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2">Descripción</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="actualProduct.description"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2">Categoría</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          :items="categories"
                          v-model="actualProduct.categoryId"
                          item-text="name"
                          item-value="categoryId"
                          @change="getSubCategories"
                          return-object
                          filled
                          shaped
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2">Subcategoría</v-subheader>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          :items="loadSubCategories"
                          v-model="actualProduct.subCategoryId"
                          item-text="name"
                          item-value="subCategoryId"
                          filled
                          shaped
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2">Impuesto</v-subheader>
                      </v-col>
                      <v-col cols="5">
                        <v-select
                          :items="taxList"
                          v-model="actualProduct.taxId"
                          :item-text="text"
                          item-value="taxId"
                          filled
                          shaped
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          class="checkBoxTax"
                          v-model="actualProduct.taxIncluded"
                          label="Impuesto incluido"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        <v-subheader class="my-2" shrink="true"
                          >Precio base</v-subheader
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-text-field
                          v-model="actualProduct.basePrice"
                          filled
                          shaped
                          required
                          type="number"
                          min="0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="this.actualProduct.productImages.length > 0">
                      <v-col class="img-container" cols="12">
                        <v-img
                          class="img-product"
                          v-for="(img, index) in actualProduct.productImages"
                          :key="index"
                          :alt="img.description"
                          :title="img.description"
                          max-height="200px"
                          max-width="200px"
                          contain
                          :src="imgUrl(img)"
                          ><v-icon
                            @click="deleteImage(img, index)"
                            class="cancel-icon"
                            >mdi-close-circle</v-icon
                          >
                        </v-img>
                      </v-col>
                    </v-row>

                    <v-row v-if="this.actualProduct.productId != 0">
                      <v-col cols="3">
                        <v-subheader class="my-2" shrink="true"
                          >Imagen</v-subheader
                        >
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          v-model="imgDescription"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-bind:class="{ disabled: imgDescription.trim() == '' }"
                        cols="4"
                      >
                        <input
                          id="fileUpload"
                          @change="uploadImage"
                          type="file"
                          ref="file"
                          accept="image/bmp, image/jpeg, image/png"
                          hidden
                        />
                        <v-btn class="upload-btn" @click="chooseFile()">
                          <v-icon class="upload-icon">mdi-cloud-upload</v-icon>
                          Upload img
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="product-actions d-flex justify-end"
                        >
                          <v-btn
                            class="product-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="product-btn"
                            color="primary"
                            @click="saveOrUpdate"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Product from "../models/product.js";
import productService from "../services/productService.js";
import categoryService from "../services/categoryService.js";
import taxService from "../services/taxService.js";

export default {
  name: "products",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nombre", value: "name", align: "start" },
        { text: "Descripción", value: "description", align: "left" },
        { text: "Categoría", value: "category.name", align: "left" },
        { text: "Subcategoría", value: "subCategory.name", align: "left" },
        { text: "Precio base", value: "basePrice", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogEdit: false,
      hideDeleted: true,
      actualProduct: new Product(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        0,
        [],
        true
      ),
      products: [],
      categories: [],
      subcategories: [],
      allSubcategories: [],
      taxList: [],
      imgDescription: "",
      modalTitle: "Crear producto",
    };
  },
  methods: {
    text: (item) => item.name + " — " + item.percentage,
    imgUrl: (img) =>
      process.env.VUE_APP_RESOURCES + img.filePath.replaceAll("\\", "/"),
    chooseFile() {
      if (this.imgDescription.trim() == "") {
        return;
      }
      document.getElementById("fileUpload").click();
    },
    deleteImage(img, index) {
      console.log(img);
      console.log(img.productImageId);
      productService.deleteProductImagen(img.productImageId).then(() => {
        this.actualProduct.productImages.splice(index, 1);
      });
    },
    saveOrUpdate() {
      const product = {
        ProductId: this.actualProduct.productId,
        Name: this.actualProduct.name,
        Description: this.actualProduct.description,
        CategoryId: this.actualProduct.categoryId,
        SubCategoryId: this.actualProduct.subCategoryId,
        TaxId: this.actualProduct.taxId,
        TaxIncluded: this.actualProduct.taxIncluded,
        BasePrice: Number(this.actualProduct.basePrice),
        Active: this.actualProduct.active,
      };
      console.log(product);
      if (product.ProductId == "" || product.ProductId == 0) {
        this.createProduct(product);
      } else {
        this.updateProduct(product);
      }
    },
    createProduct(product) {
      productService
        .createProduct(product)
        .then(() => {
          (this.actualProduct = new Product(
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            [],
            true
          )),
            (this.dialogEdit = false);
          productService.getProducts().then((response) => {
            this.products = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateProduct(product) {
      productService
        .updateProduct(product)
        .then(() => {
          (this.actualProduct = new Product(
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            false,
            0,
            [],
            true
          )),
            (this.dialogEdit = false);
          productService.getProducts().then((response) => {
            this.products = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteProduct(productId) {
      productService
        .deleteProduct(productId)
        .then(() => {
          productService.getProducts().then((response) => {
            this.products = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadImage(e) {
      const form = new FormData();
      form.append("ProductId", this.actualProduct.productId);
      form.append("Description", this.imgDescription.trim());
      form.append("File", e.target.files[0]);
      this.imgDescription = "";
      productService.uploadProductImagen(form).then((response) => {
        console.log(response.data);
        this.actualProduct.productImages.push(response.data);
      });
    },
    editProduct(item) {
      this.modalTitle = "Editar producto";
      this.actualProduct = new Product(
        item.productId,
        item.companyId,
        item.company,
        item.name,
        item.description,
        item.categoryId,
        item.category,
        item.subCategoryId,
        item.subCategory,
        item.taxId,
        item.taxIncluded,
        item.basePrice,
        item.productImages,
        item.active
      );
      console.log(this.actualProduct);
      this.getSubCategories(this.actualProduct.category);
      this.imgDescription = "";
      this.dialogEdit = true;
    },
    createModalProduct() {
      this.modalTitle = "Crear producto";
      (this.actualProduct = new Product(
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        0,
        [],
        true
      )),
        (this.dialogEdit = true);
    },
    getSubCategories(value) {
      this.actualProduct.category = value;
      this.actualProduct.categoryId = value.categoryId;
      categoryService
        .getSubCategories(value)
        .then((response) => {
          this.subcategories = response.data.filter((subcategory) => {
            return subcategory.active == true;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    loadSubCategories() {
      return this.subcategories;
    },
    productsFiltered() {
      if (this.hideDeleted) {
        return this.products.filter((product) => product.active == true);
      } else {
        return this.products;
      }
    },
  },
  mounted() {
    productService.getProducts().then((response) => {
      this.products = response.data;
    });
    categoryService.getCategories().then((response) => {
      this.categories = response.data.filter((category) => {
        return category.active == true;
      });
    });
    categoryService.getAllSubCategories().then((response) => {
      this.allSubcategories = response.data.filter((subcategory) => {
        return subcategory.active == true;
      });
    });
    taxService.getTaxes().then((response) => {
      this.taxList = response.data.filter((tax) => {
        return tax.active == true;
      });
    });
  },
};
</script>

<style scoped>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.products-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}
.checkBoxTax {
  margin: 0px;
  margin-left: 20px;
}

.img-container {
  display: flex;
  flex-direction: inherit;
  justify-content: start;
  align-content: flex-start;
  align-items: flex-start;
  border: solid 2px #d0d3d4;
  margin: 10px;
  border-radius: 5px;
  flex-wrap: inherit;
  max-height: 325px;
  overflow: auto;
}
.img-product {
  max-height: 140px;
  height: 140px;
  max-width: 160px;
  margin: 10px;
  overflow: initial;
  border: solid 1px #d0d3d4;
}

.cancel-icon {
  position: absolute !important;
  top: -10px;
  right: -13px;
  color: #444 !important;
}

.disabled {
  opacity: 0.3 !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
  font-size: 1.2rem;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
