export default class Product {
  constructor(
    productId,
    companyId,
    company,
    name,
    description,
    categoryId,
    category,
    subCategoryId,
    subCategory,
    taxId,
    taxIncluded,
    basePrice,
    productImages,
    active
  ) {
    this.productId = productId;
    this.companyId = companyId;
    this.company = company;
    this.name = name;
    this.description = description;
    this.categoryId = categoryId;
    this.category = category;
    this.subCategoryId = subCategoryId;
    this.subCategory = subCategory;
    this.taxId = taxId;
    this.taxIncluded = taxIncluded;
    this.basePrice = basePrice;
    this.productImages = productImages;
    this.active = active;
  }
}
