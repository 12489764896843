import axios from "axios";
import Utils from "../Utils/Utils.js";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getUsers() {
    return axios.get(ENDPOINT_PATH + "auth", {
      headers: Utils.getHeaders(),
    });
  },
  createUser(user) {
    return axios.post(ENDPOINT_PATH + "auth/Signup", user, {
      headers: Utils.getHeaders(),
    });
  },
  updateUser(user) {
    return axios.put(ENDPOINT_PATH + "auth", user, {
      headers: Utils.getHeaders(),
    });
  },
  deleteUser(email) {
    return axios.delete(ENDPOINT_PATH + "auth/" + email, {
      headers: Utils.getHeaders(),
    });
  },
};
