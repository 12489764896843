export default class SaleProductOption {
  constructor(
    saleProductOptionId,
    saleProductId,
    featureOptionId,
    basePrice,
    amount
  ) {
    this.saleProductOptionId = saleProductOptionId;
    this.saleProductId = saleProductId;
    this.featureOptionId = featureOptionId;
    this.basePrice = basePrice;
    this.amount = amount;
  }
}
