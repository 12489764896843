<template>
  <v-container fluid fill-height>
    <div id="background"></div>
    <div id="background-shadow"></div>
    <v-layout class="main-container" justify-center>
      <v-flex class="flexStyles" xs12 sm8 md8>
        <p class="page-title">Maestros</p>
        <v-row
          ><v-icon>mdi-shape</v-icon>
          <p class="my-2">
            <v-icon>mdi-circle-small</v-icon> Categorías
          </p></v-row
        >
        <v-card
          class="elevation-2 rounded-lg d-flex flex-column align-center categories-card"
          color="secondary"
        >
          <v-toolbar dark class="elevation-0 toolbar" color="primary">
            <v-toolbar-title>Listado de categorías</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              filled
              rounded
              dense
              single-line
              hide-details
              class="search"
            ></v-text-field>
            <v-btn
              class="mx-4 elevation-0 btn-add-category"
              fab
              dark
              small
              color="primaryButton"
              @click.stop="createModalCategory()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="categoriesFiltered"
            :search="search"
            class="table"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="editModalCategory(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar categoría</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="createModalSubCategory(item)"
                    >mdi-flare</v-icon
                  >
                </template>
                <span>Crear subcategoría</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class=""
                    color="primary"
                    @click.stop="viewSubCategories(item)"
                    >mdi-format-list-bulleted</v-icon
                  >
                </template>
                <span>Ver subcategorías</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mx-2"
                    color="primary"
                    @click="deleteCategory(item.categoryId)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar categoría</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-icon
                v-if="item.active == 1"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-check-circle</v-icon
              >
              <v-icon
                v-if="item.active == 0"
                small
                v-bind="attrs"
                class=""
                color="primary"
                >mdi-minus-circle</v-icon
              >
            </template>
          </v-data-table>
          <v-dialog
            v-model="dialogEdit"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualCategory.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Descripción</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualCategory.description"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="category-actions d-flex justify-end"
                        >
                          <v-btn
                            class="category-btn"
                            color="primary"
                            @click="dialogEdit = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="category-btn"
                            color="primary"
                            @click="saveOrUpdateCategory"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogEditSub"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>{{ modalTitleSub }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="card-text">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Nombre</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualSubCategory.name"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">
                        <v-subheader class="my-2" shrink="true"
                          >Descripción</v-subheader
                        >
                      </v-col>
                      <v-col cols="10">
                        <v-text-field
                          v-model="actualSubCategory.description"
                          filled
                          shaped
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-card-actions
                          class="category-actions d-flex justify-end"
                        >
                          <v-btn
                            class="category-btn"
                            color="primary"
                            @click="dialogEditSub = false"
                          >
                            Cerrar</v-btn
                          >
                          <v-btn
                            class="category-btn"
                            color="primary"
                            @click="saveOrUpdateSubCategory"
                          >
                            Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogSubTable"
            persistent
            width="700px"
            max-width="700px"
          >
            <v-card
              class="elevation-2 rounded-lg d-flex flex-column align-center"
              color="secondary"
            >
              <v-toolbar dark class="elevation-0 toolbar" color="primary">
                <v-toolbar-title>Listado de subcategorías</v-toolbar-title>
              </v-toolbar>
              <v-data-table
                :headers="headersSub"
                :items="subCategoriesFiltered"
                class="table"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class=""
                        color="primary"
                        @click.stop="editModalSubCategory(item)"
                        >mdi-pencil</v-icon
                      >
                    </template>
                    <span>Editar subcategoría</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2"
                        color="primary"
                        @click="deleteSubCategory(item.subCategoryId)"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Eliminar subcategoría</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.active`]="{ item }">
                  <v-icon
                    v-if="item.active == 1"
                    small
                    v-bind="attrs"
                    class=""
                    color="primary"
                    >mdi-check-circle</v-icon
                  >
                  <v-icon
                    v-if="item.active == 0"
                    small
                    v-bind="attrs"
                    class=""
                    color="primary"
                    >mdi-minus-circle</v-icon
                  >
                </template>
              </v-data-table>
              <v-card-actions class="category-actions d-flex justify-end">
                <v-btn
                  class="category-btn"
                  color="primary"
                  @click="dialogSubTable = false"
                >
                  Cerrar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Category from "../models/category.js";
import SubCategory from "../models/subCategory.js";
import categoryService from "../services/categoryService.js";

export default {
  name: "categories",
  props: {
    source: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descripción", value: "description", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      headersSub: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descripción", value: "description", align: "left" },
        { text: "Activo", value: "active", align: "left" },
        { text: "Acciones", value: "actions", align: "left" },
      ],
      dialogEdit: false,
      actualCategory: new Category("", "", "", "", "", true),
      categories: [],
      hideDeleted: true,
      hideDeletedSub: true,
      subCategories: [],
      dialogSubTable: false,
      actualSubCategory: new SubCategory("", "", "", "", "", true),
      dialogEditSub: false,
      modalTitle: "Crear categoría",
      modalTitleSub: "Crear subcategoría",
    };
  },
  methods: {
    saveOrUpdateCategory() {
      const category = {
        CategoryId: this.actualCategory.categoryId,
        CompanyId: this.actualCategory.companyId,
        Company: this.actualCategory.copmany,
        Name: this.actualCategory.name,
        Description: this.actualCategory.description,
        Active: this.actualCategory.active,
      };
      if (category.CategoryId == "" || category.CategoryId == 0) {
        delete category.CategoryId;
        delete category.CompanyId;
        delete category.Company;
        delete category.Active;
        this.createCategory(category);
      } else {
        this.updateCategory(category);
      }
    },
    saveOrUpdateSubCategory() {
      const subCategory = {
        SubCategoryId: this.actualSubCategory.subCategoryId,
        CategoryId: this.actualSubCategory.categoryId,
        Name: this.actualSubCategory.name,
        Description: this.actualSubCategory.description,
        Active: this.actualSubCategory.active,
      };
      if (subCategory.SubCategoryId == "" || subCategory.SubCategoryId == 0) {
        delete subCategory.SubCategoryId;
        delete subCategory.Active;
        this.createSubCategory(subCategory);
      } else {
        this.updateSubCategory(subCategory);
      }
    },
    createCategory(category) {
      categoryService
        .createCategory(category)
        .then(() => {
          this.actualCategory = new Category("", "", "", "", "", true);
          this.dialogEdit = false;
          categoryService.getCategories().then((response) => {
            this.categories = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createSubCategory(subCategory) {
      categoryService
        .createSubCategory(subCategory)
        .then(() => {
          this.actualSubCategory = new SubCategory("", "", "", "", "", true);
          this.actualCategory = new Category("", "", "", "", "", true);
          this.dialogEditSub = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateCategory(category) {
      categoryService
        .updateCategory(category)
        .then(() => {
          this.actualCategory = new Category("", "", "", "", "", "", true);
          this.dialogEdit = false;
          categoryService
            .getCategories(this.actualCategory)
            .then((response) => {
              this.categories = response.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSubCategory(subCategory) {
      categoryService
        .updateSubCategory(subCategory)
        .then(() => {
          this.actualSubCategory = new SubCategory(
            "",
            "",
            "",
            "",
            "",
            "",
            true
          );
          this.dialogEditSub = false;
          categoryService
            .getSubCategories(this.actualCategory)
            .then((response) => {
              this.subCategories = response.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCategory(categoryId) {
      categoryService
        .deleteCategory(categoryId)
        .then(() => {
          categoryService.getCategories().then((response) => {
            this.categories = response.data;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSubCategory(subCategoryId) {
      categoryService
        .deleteSubCategory(subCategoryId)
        .then(() => {
          categoryService
            .getSubCategories(this.actualCategory)
            .then((response) => {
              this.subCategories = response.data;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createModalCategory() {
      this.modalTitle = "Editar categoría";
      this.actualCategory = new Category("", "", "", "", "", "", true);
      this.dialogEdit = true;
    },
    editModalCategory(item) {
      this.modalTitle = "Editar categoría";
      this.actualCategory = new Category(
        item.categoryId,
        item.companyId,
        item.copmany,
        item.name,
        item.description,
        item.active
      );
      this.dialogEdit = true;
    },
    createModalSubCategory(item) {
      this.modalTitleSub = "Crear subcategoría";
      this.actualSubCategory = new SubCategory(
        "",
        item.categoryId,
        item,
        "",
        "",
        "",
        true
      );
      this.actualCategory = new Category(
        item.categoryId,
        item.companyId,
        item.copmany,
        item.name,
        item.description,
        item.active
      );
      this.dialogEditSub = true;
    },
    viewSubCategories(item) {
      this.actualCategory = new Category(
        item.categoryId,
        item.companyId,
        item.copmany,
        item.name,
        item.description,
        item.active
      );
      categoryService.getSubCategories(item).then((response) => {
        this.subCategories = response.data;
      });
      this.dialogSubTable = true;
    },
    editModalSubCategory(item) {
      this.modalTitleSub = "Editar subcategoría";
      this.actualSubCategory = new SubCategory(
        item.subCategoryId,
        item.categoryId,
        item.category,
        item.name,
        item.description,
        item.active
      );
      this.dialogEditSub = true;
    },
  },
  mounted() {
    categoryService.getCategories().then((response) => {
      this.categories = response.data;
    });
  },
  computed: {
    categoriesFiltered() {
      if (this.hideDeleted) {
        return this.categories.filter((category) => category.active == true);
      } else {
        return this.categories;
      }
    },
    subCategoriesFiltered() {
      if (this.hideDeletedSub) {
        return this.subCategories.filter(
          (subCategory) => subCategory.active == true
        );
      } else {
        return this.subCategories;
      }
    },
  },
};
</script>

<style>
.col {
  padding: 0;
}

.card-text {
  padding: 5% !important;
}

.v-input__slot {
  min-height: 46px !important;
}

.main-container {
  margin-top: 5%;
}

.toolbar {
  width: 100%;
  margin-bottom: 0;
}

.table {
  width: 100%;
  height: 100%;
  background-color: var(--v-secondary-base) !important;
}

.v-input .theme--dark.v-label {
  color: #000 !important;
}

.v-input input {
  color: #000 !important;
}

.v-input .v-input__icon .v-icon {
  color: var(--v-primary-base) !important;
}

.search {
  background-color: rgba(255, 255, 255, 0.7);
}

.categories-card {
  margin-left: 15%;
}

.v-main {
  padding: 20px 20px 20px 20px !important;
}

#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background-color: var(--v-primary-base);
  z-index: 0;
}

.flexStyles {
  z-index: 1;
  color: white;
}

.flexStyles .v-icon {
  color: white;
}

#background-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.2 !important;
  z-index: 1;
  background-color: black;
}

.page-title {
  font-size: 1.4rem !important;
}
</style>
