var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path != '/Login' ? true : false),expression:"$route.path != '/Login' ? true : false"}],staticClass:"elevation-0",attrs:{"app":"","color":"terciary"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),_vm._v(" Bienvenido, "),_c('b',[_vm._v(_vm._s(_vm.username))]),_c('v-btn',{staticClass:"btn-profile",attrs:{"to":"/profile","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Mi perfil")]),_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-btn',{staticClass:"btn-logout",attrs:{"text":""},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-2"},[_vm._v("Cerrar sesión")]),_c('v-icon',[_vm._v("mdi-power")])],1)],1),_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path != '/Login' ? true : false),expression:"$route.path != '/Login' ? true : false"}],attrs:{"app":"","color":"secondary"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"drawer-image",attrs:{"max-height":"50%","max-width":"50%","contain":"","src":require("./assets/logo.png")}})],1),_c('v-subheader',{staticClass:"menu-header"},[_vm._v("MAESTROS")]),_c('v-list',_vm._l((_vm.items),function(ref,i){
var styles = ref[0];
var icon = ref[1];
var text = ref[2];
var route = ref[3];
return _c('v-list-item',{key:i,attrs:{"link":"","router":"","to":route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:styles},[_vm._v(_vm._s(text))])],1)],1)}),1),(_vm.isAdmin)?_c('v-subheader',{staticClass:"menu-header"},[_vm._v("CONFIGURACIÓN")]):_vm._e(),(_vm.isAdmin)?_c('v-list',_vm._l((_vm.itemsConfig),function(ref,i){
var styles = ref[0];
var icon = ref[1];
var text = ref[2];
var route = ref[3];
return _c('v-list-item',{key:i,attrs:{"link":"","router":"","to":route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:styles},[_vm._v(_vm._s(text))])],1)],1)}),1):_vm._e()],1),_c('v-main',{staticClass:"main-app"},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }