import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH = process.env.VUE_APP_API;

export default {
  getFeatures() {
    return axios.get(ENDPOINT_PATH + "feature", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  getFeaturesBySubcategory(subCategoryId) {
    return axios.get(
      ENDPOINT_PATH + "subcategory/" + subCategoryId + "/feature",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("userToken"),
        },
      }
    );
  },
  createFeature(feature) {
    console.log(ENDPOINT_PATH + "feature");
    return axios.post(ENDPOINT_PATH + "feature", feature, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  updateFeature(feature) {
    return axios.put(ENDPOINT_PATH + "feature", feature, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  deleteFeature(featureId) {
    return axios.delete(
      ENDPOINT_PATH + "feature/" + featureId + "?featureId=" + featureId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("userToken"),
        },
      }
    );
  },
  getFeatureOptions(feature) {
    return axios.get(
      ENDPOINT_PATH + "feature/" + feature.featureId + "/featureOption",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("userToken"),
        },
      }
    );
  },
  createFeatureOption(featureOption) {
    return axios.post(ENDPOINT_PATH + "featureOption", featureOption, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  updateFeatureOption(featureOption) {
    return axios.put(ENDPOINT_PATH + "featureOption", featureOption, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("userToken"),
      },
    });
  },
  deleteFeatureOption(FeatureOptionId) {
    return axios.delete(
      ENDPOINT_PATH +
        "featureOption/" +
        FeatureOptionId +
        "?FeatureOptionId=" +
        FeatureOptionId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("userToken"),
        },
      }
    );
  },
};
