<template>
  <Reports />
</template>

<script>
// @ is an alias to /src
import Reports from "@/components/Reports.vue";

export default {
  name: "reports",
  components: {
    Reports,
  },
};
</script>
