<template>
  <v-container class="background-login" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex class="login-block" xs12 sm8 md2>
        <v-card
          class="elevation-2 login-card rounded-0 d-flex flex-column align-center"
          color="secondary"
        >
          <v-img
            max-height="90%"
            max-width="90%"
            contain
            src="../assets/logo.png"
            class="login-image"
          ></v-img>
          <v-card-text>
            <div class="welcome-text">
              <p><b>Bienvenido</b></p>
              <p>Introduzca sus credenciales a continuación</p>
            </div>
            <v-form ref="form">
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                name="login"
                label="Usuario"
                type="text"
                background-color="terciary"
              ></v-text-field>
              <v-text-field
                v-model="user.password"
                :rules="passwordRules"
                id="password"
                name="password"
                label="Contraseña"
                type="password"
                background-color="terciary"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="login-actions">
            <v-btn class="login-btn" color="primary" @click="validate"
              >ENTRAR</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar" color="error">
          {{ snackbarText }}
        </v-snackbar>
      </v-flex>
      <v-img class="img-innobonos" 
        contain
        src="../assets/logos-innobonos-2021-negativo.png">
    </v-layout>
  </v-container>
</template>

<script>
import User from "../models/user.js";
import authService from "../services/authService.js";

export default {
  name: "login",
  props: {
    source: String,
  },
  data() {
    return {
      user: new User("", ""),
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "password is required"],
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.login();
    },
    async login() {
      try {
        await authService
          .login(this.user.email, this.user.password)
          .then((response) => {
            if (response.data) {
              const userAuth = response.data;
              authService.setUserLogged(userAuth);
              this.$router.replace("/sales");
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.snackbarText = "El usuario o la contraseña son incorrectos";
              this.snackbar = true;
            } else if (error.response.status == 403) {
              this.snackbarText = "Este usuario no tiene acceso permitido";
              this.snackbar = true;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.login-block {
  height: 100%;
  margin-top: 15%;
}
.login-card {
  height: 60%;
}
.v-main {
  padding: 20px 20px 20px 20px !important;
}

.welcome-text {
  text-align: center;
}

.login-image {
  padding: 15%;
}

.login-actions {
  width: 100%;
  padding-bottom: 50px !important;
}

.login-btn {
  width: 100%;
}

label {
  left: 10px !important;
}

.v-text-field input {
  padding-left: 10px !important;
}

.img-innobonos {
  position: fixed;
  bottom: 5px;
  width: 60%;
  height: auto;
}

.background-login {
  background-image: url("../assets/cakeBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
