export default class Company {
  constructor(
    companyId,
    taxTypeId,
    taxType,
    name,
    companyIdentifier,
    alias,
    address,
    phoneNumber,
    logoPath,
    email,
    active
  ) {
    this.companyId = companyId;
    this.taxTypeId = taxTypeId;
    this.taxType = taxType;
    this.name = name;
    this.companyIdentifier = companyIdentifier;
    this.alias = alias;
    this.address = address;
    this.phoneNumber = phoneNumber;
    this.logoPath = logoPath;
    this.email = email;
    this.active = active;
  }
}
