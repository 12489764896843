var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('div',{attrs:{"id":"background"}}),_c('div',{attrs:{"id":"background-shadow"}}),_c('v-layout',{staticClass:"main-container",attrs:{"justify-center":""}},[_c('v-flex',{staticClass:"flexStyles",attrs:{"xs12":"","sm8":"","md8":""}},[_c('p',{staticClass:"page-title"},[_vm._v("Configuración")]),_c('v-row',[_c('v-icon',[_vm._v("mdi-account-group")]),_c('p',{staticClass:"my-2"},[_c('v-icon',[_vm._v("mdi-circle-small")]),_vm._v(" Informes")],1)],1),_c('v-card',{staticClass:"elevation-2 rounded-lg d-flex flex-column align-center reports-card",attrs:{"color":"secondary"}},[_c('v-toolbar',{staticClass:"elevation-0 toolbar",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Informes")])],1),_c('v-tabs',{attrs:{"vertical":""}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-group ")]),_vm._v(" Ventas por clientes ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cake ")]),_vm._v(" Ventas por productos ")],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"dates"},[_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialogInitialCustomer",attrs:{"return-value":_vm.initialDateCustomers,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.initialDateCustomers=$event},"update:return-value":function($event){_vm.initialDateCustomers=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicial","readonly":"","filled":"","shaped":"","prepend-icon":"mdi-calendar-range"},model:{value:(_vm.initialDateCustomers),callback:function ($$v) {_vm.initialDateCustomers=$$v},expression:"initialDateCustomers"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalInitialDateCustomers),callback:function ($$v) {_vm.modalInitialDateCustomers=$$v},expression:"modalInitialDateCustomers"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es"},model:{value:(_vm.initialDateCustomers),callback:function ($$v) {_vm.initialDateCustomers=$$v},expression:"initialDateCustomers"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalInitialDateCustomers = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogInitialCustomer.save(
                            _vm.initialDateCustomers
                          )}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialogFinalCustomer",attrs:{"return-value":_vm.finalDateCustomers,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.finalDateCustomers=$event},"update:return-value":function($event){_vm.finalDateCustomers=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha final","filled":"","shaped":"","prepend-icon":"mdi-calendar-range","readonly":""},model:{value:(_vm.finalDateCustomers),callback:function ($$v) {_vm.finalDateCustomers=$$v},expression:"finalDateCustomers"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFinalDateCustomers),callback:function ($$v) {_vm.modalFinalDateCustomers=$$v},expression:"modalFinalDateCustomers"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es"},model:{value:(_vm.finalDateCustomers),callback:function ($$v) {_vm.finalDateCustomers=$$v},expression:"finalDateCustomers"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFinalDateCustomers = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogFinalCustomer.save(_vm.finalDateCustomers)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Clientes","filled":"","shaped":"","prepend-icon":"mdi-account-group","items":_vm.customers,"item-text":"name","item-value":"clientId","multiple":"","required":""},model:{value:(_vm.customersSelected),callback:function ($$v) {_vm.customersSelected=$$v},expression:"customersSelected"}})],1)],1),_c('v-card-text',{staticClass:"chart"},[_c('bar-chart',{attrs:{"chartdata":_vm.getChartDataCustomers,"options":_vm.options}})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"dates"},[_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialogInitialProduct",attrs:{"return-value":_vm.initialDateProducts,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.initialDateProducts=$event},"update:return-value":function($event){_vm.initialDateProducts=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicial","readonly":"","filled":"","shaped":"","prepend-icon":"mdi-calendar-range"},model:{value:(_vm.initialDateProducts),callback:function ($$v) {_vm.initialDateProducts=$$v},expression:"initialDateProducts"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalInitialDateProducts),callback:function ($$v) {_vm.modalInitialDateProducts=$$v},expression:"modalInitialDateProducts"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es"},model:{value:(_vm.initialDateProducts),callback:function ($$v) {_vm.initialDateProducts=$$v},expression:"initialDateProducts"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalInitialDateProducts = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogInitialProduct.save(_vm.initialDateProducts)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-dialog',{ref:"dialogFinalProduct",attrs:{"return-value":_vm.finalDateProducts,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.finalDateProducts=$event},"update:return-value":function($event){_vm.finalDateProducts=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha final","filled":"","shaped":"","prepend-icon":"mdi-calendar-range","readonly":""},model:{value:(_vm.finalDateProducts),callback:function ($$v) {_vm.finalDateProducts=$$v},expression:"finalDateProducts"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFinalDateProducts),callback:function ($$v) {_vm.modalFinalDateProducts=$$v},expression:"modalFinalDateProducts"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es"},model:{value:(_vm.finalDateProducts),callback:function ($$v) {_vm.finalDateProducts=$$v},expression:"finalDateProducts"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFinalDateProducts = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogFinalProduct.save(_vm.finalDateProducts)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"inputs-fields",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":"Productos","filled":"","shaped":"","prepend-icon":"mdi-account-group","items":_vm.products,"item-text":"name","item-value":"productId","multiple":"","required":""},model:{value:(_vm.productsSelected),callback:function ($$v) {_vm.productsSelected=$$v},expression:"productsSelected"}})],1)],1),_c('v-card-text',{staticClass:"chart"},[_c('bar-chart',{attrs:{"chartdata":_vm.getChartDataProducts,"options":_vm.options}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }