<template>
  <Profile />
</template>

<script>
// @ is an alias to /src
import Profile from "@/components/Profile.vue";

export default {
  name: "profile",
  components: {
    Profile,
  },
};
</script>
