import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import authService from "./services/authService";
import vuetify from "./plugins/vuetify";
import router from "./router";
import Cookies from "js-cookie";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = false;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      } else {
        isRefreshing = true;
        originalRequest._retry = true;
        if (authService.getUserLogged()) {
          await authService
            .login(
              JSON.parse(Cookies.get("userCredentials")).email,
              JSON.parse(Cookies.get("userCredentials")).password
            )
            .then((response) => {
              isRefreshing = false;
              if (response.data.value && response.data.statusCode == 200) {
                let user = response.data.value;
                authService.setUserLogged(user);

                originalRequest.headers["Authorization"] =
                  "Bearer " + user.ticket;
                processQueue(null, user.ticket);
                return axios(originalRequest);
              }
            })
            .catch((error) => {
              authService.logout();
              router.replace("/Login");
              return Promise.reject(error);
            });
        } else {
          return Promise.reject(error);
        }
      }
    } else if (
      (originalRequest._retry && error.response.status === 403) ||
      (originalRequest._retry && error.response.status === 401)
    ) {
      authService.logout();
      router.replace("/Login");
      return Promise.reject(error);
    }
  }
);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
