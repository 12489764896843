<template>
  <Taxes />
</template>

<script>
// @ is an alias to /src
import Taxes from "@/components/Taxes.vue";

export default {
  name: "taxes",
  components: {
    Taxes,
  },
};
</script>
