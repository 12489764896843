<template>
  <Company />
</template>

<script>
// @ is an alias to /src
import Company from "@/components/Company.vue";

export default {
  name: "company",
  components: {
    Company,
  },
};
</script>
